import React, { ReactElement } from 'react';
import { Link } from '@vibTheme/components/_base/Link';
import { Breadcrumbs } from '@mui/material';
import { Typography } from '@vibTheme/components/_base/Typography';

export interface BreadcrumbItem {
  label: string;
  icon?: ReactElement;
  href?: string;
}

export default function VibBreadcrumbs({ items }: { items: BreadcrumbItem[] }) {
  return (
    <Breadcrumbs separator=">" className="mb-main">
      {items.map((item) => {
        const Icon = () =>
          item.icon
            ? React.cloneElement(item.icon, { className: 'mr-sm' })
            : null;

        if (item.href) {
          return (
            <Link
              key={item.label}
              href={item.href}
              className="flex items-center text-gray-400"
            >
              <Icon />
              <span className="max-w-[19rem] sm:max-w-none overflow-hidden whitespace-nowrap py-1 text-ellipsis">
                {item.label}
              </span>
            </Link>
          );
        } else {
          return (
            <Typography
              key={item.label}
              className="flex items-center text-gray-400"
            >
              <Icon />
              <span className="max-w-[19rem] sm:max-w-none overflow-hidden whitespace-nowrap py-1 text-ellipsis">
                {item.label}
              </span>
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
}
