import React, { Dispatch, SetStateAction } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InputDate } from '@vibTheme/components/_base/Input';
import { Button } from '@vibTheme/components/_base/Button';
import {
  CustomPeriodRangeForm,
  customPeriodRangeSchema,
  DashboardFilters,
} from '@interfaces/dashboard';
import { zodResolver } from '@hookform/resolvers/zod';

type CustomPeriodRangeProps = {
  setDashboardFilters: Dispatch<SetStateAction<DashboardFilters>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const CustomPeriodRange = (props: CustomPeriodRangeProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomPeriodRangeForm>({
    resolver: zodResolver(customPeriodRangeSchema),
  });

  const closeModal = () => props.setIsOpen(false);

  const onSubmit: SubmitHandler<CustomPeriodRangeForm> = (data) => {
    const start = new Date(data.startDate).getTime();
    const end = new Date(data.endDate).getTime();
    const range = end - start;

    const previousPeriodStartDate = new Date(start - range);
    const previousPeriodEndDate = new Date(end - range);

    const dates: Partial<DashboardFilters> = {
      ...data,
      previousPeriodStartDate,
      previousPeriodEndDate,
    };

    props.setDashboardFilters((dashboardFilters) => ({
      ...dashboardFilters,
      ...dates,
    }));

    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid gap-main">
      <InputDate
        type="date"
        control={control}
        name="startDate"
        label="Início"
        error={!!errors.startDate}
        helperText={errors.startDate?.message}
      />
      <InputDate
        type="date"
        control={control}
        name="endDate"
        label="Fim"
        error={!!errors.endDate}
        helperText={errors.endDate?.message}
      />

      <div className="flex justify-between">
        <Button variant="outlined" onClick={closeModal}>
          Cancelar
        </Button>
        <Button type="submit">Confirmar</Button>
      </div>
    </form>
  );
};
