type DateType = 'datetime' | 'date';
type ParseDateOptions = {
  type?: DateType;
  convertToLocale?: boolean;
};

export default function parseDate(
  originalDate: string | Date,
  options: ParseDateOptions = { type: 'datetime', convertToLocale: true },
) {
  if (!originalDate) return '';

  const date = new Date(originalDate);
  const timezoneInMs = date.getTimezoneOffset() * 1000 * 60;

  !options.convertToLocale && date.setTime(date.getTime() + timezoneInMs);

  return options.type === 'date'
    ? date.toLocaleDateString()
    : date.toLocaleString();
}
