export default function parseMoneyValue(value: number | string) {
  if (value) {
    const [truncatedValue] = value
      .toString()
      .match(/^-?\d+(?:\.\d{0,2})?/) as RegExpMatchArray;

    return Number(truncatedValue).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumIntegerDigits: 1,
    });
  } else {
    return 'R$ 0,00';
  }
}
