import React from 'react';
import { DashboardFilters } from '@interfaces/dashboard';
import { SalesCharts } from './SalesCharts';
import { GroupMetricsCharts } from './GroupMetricsCharts';
import { SalesHistoryChart } from './SalesHistoryChart';
import { Paper } from '@mui/material';
import { Typography } from '@vibTheme/components/_base/Typography';
import styles from '@styles/Card.module.css';

type CardProps = {
  dashBoardFilters: DashboardFilters;
};

export function DashboardCard({ dashBoardFilters }: CardProps) {
  const {
    botConfigIds,
    startDate,
    endDate,
    previousPeriodStartDate,
    previousPeriodEndDate,
    clientTz,
  } = dashBoardFilters;

  return (
    <section>
      <SalesCharts
        filters={{
          botConfigIds,
          startDate,
          endDate,
          previousPeriodStartDate,
          previousPeriodEndDate,
          clientTz,
        }}
      />
      <Paper className={styles.gridHistory}>
        <Typography component="h3" className="p-main">
          Histórico de Vendas
        </Typography>
        <SalesHistoryChart
          filters={{
            botConfigIds,
            clientTz,
          }}
        />
      </Paper>
      <GroupMetricsCharts
        filters={{
          botConfigIds,
          startDate,
          endDate,
        }}
      />
    </section>
  );
}
