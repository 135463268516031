import { IconButton, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import styles from '@styles/Card.module.css';

export const CustomHelpTooltip = ({ description }: { description: string }) => (
  <Tooltip
    placement="right"
    title={description}
    enterTouchDelay={0}
    arrow
    classes={{
      tooltip: styles.helpTooltip,
      arrow: styles.helpTooltipArrow,
    }}
  >
    <IconButton>
      <HelpOutlineIcon fontSize="small" />
    </IconButton>
  </Tooltip>
);
