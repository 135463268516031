import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@vibTheme/components/_base/Typography';
import styles from '@styles/Card.module.css';
import { getDefaultMessage } from '@utils/getDefaultDashboardTipMessage';
import { CustomHelpTooltip } from './CustomHelpTooltip';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        {...props}
        style={{
          width: '5rem',
          margin: '1.5rem 1rem 0 0',
          color: '#F3F4F6',
        }}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        {...props}
        style={{
          width: '5rem',
          margin: '1.5rem 1rem 0 0',
          position: 'absolute',
          left: 0,
        }}
      />
      <Box
        sx={{
          top: 34,
          left: 50,
          position: 'absolute',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export function GroupMetricsChart({
  chartData,
  isLoading,
  title,
  label,
}: {
  isLoading: boolean;
  chartData: { newValue: number; totalValue: number };
  title: string;
  label: { new: string; total: string };
}) {
  return (
    <Box className={styles.cardUser}>
      {isLoading ? (
        <Box className="flex justify-center items-center h-[180px]">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box className="flex justify-between">
            <Typography component="h3" className={styles.typographyUser}>
              {title}
            </Typography>
            <CustomHelpTooltip
              description={getDefaultMessage(
                'newUsersInfo',
                chartData.newValue,
                chartData.totalValue,
                (
                  (chartData.newValue / (chartData.totalValue || 1)) *
                  100
                ).toFixed(2),
                title.toLowerCase(),
                label.new.toLowerCase(),
              )}
            />
          </Box>
          <Box className="flex justify-between">
            <Box>
              <Typography className={styles.typographyNewUser}>
                {label.new}
              </Typography>
              <Typography className={styles.typographyValue}>
                {chartData.newValue}
              </Typography>
              <Typography className={styles.typographyTotalUser}>
                {label.total}
              </Typography>
              <Typography className={styles.typographyValue}>
                {chartData.totalValue}
              </Typography>
            </Box>
            <CircularProgressWithLabel
              value={(chartData.newValue / (chartData.totalValue || 1)) * 100}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
