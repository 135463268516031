import { Box, CircularProgress } from '@mui/material';
import { Typography } from '@vibTheme/components/_base/Typography';
import parseMoneyValue from '@utils/parseMoneyValue';
import React, { ReactNode } from 'react';
import { AreaChart, Area, ResponsiveContainer, Tooltip } from 'recharts';
import styles from '@styles/Card.module.css';
import parseDate from '@utils/parseDate';

const GradientColors = () => {
  return (
    <linearGradient id="gradient-chart" x1="0" y1="0" x2="0" y2="1.5">
      <stop offset="20%" stopColor="#712AD9" stopOpacity={1} />
      <stop offset="80%" stopColor="#FFFFFF" stopOpacity={0} />
    </linearGradient>
  );
};
type ChartData = {
  value: number | string;
  date: string;
};

const CustomTooltip = ({
  active,
  payload,
  title,
  isMoneyChart = false,
}: {
  active?: boolean;
  data: any;
  payload?: { payload: ChartData }[];
  title: string;
  isMoneyChart: boolean;
}) => {
  if (active && payload && payload.length) {
    return (
      <Box>
        <Box>
          {payload.map(({ payload }, idx: number) => {
            const date = parseDate(payload.date, {
              type: 'date',
              convertToLocale: false,
            });

            let value = payload.value;
            if (isMoneyChart) {
              value = parseMoneyValue(value) || 0;
            }
            return (
              <Box
                key={idx}
                style={{
                  display: 'inline-block',
                  padding: 10,
                  backgroundColor: 'rgba(113, 42, 217, 0.8)',
                  borderRadius: '5px',
                }}
              >
                <Typography className="text-white text-xs">{`Data ${date}`}</Typography>
                <Typography className="text-white text-xs">{`${title}: ${value}`}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  return null;
};

const CustomAreaChart = ({
  data,
  title,
  isMoneyChart = false,
}: {
  data?: ChartData[];
  title: string;
  isMoneyChart: boolean;
}) => {
  return (
    <ResponsiveContainer height={60}>
      <AreaChart margin={{ top: 5, bottom: 5 }} data={data}>
        <defs>
          <GradientColors />
        </defs>
        <Tooltip
          content={
            <CustomTooltip
              title={title}
              data={data}
              isMoneyChart={isMoneyChart}
            />
          }
        />
        <Area
          dataKey="value"
          stroke="#712AD9"
          strokeWidth={1.5}
          fill="url(#gradient-chart)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export function SalesChart({
  label,
  title,
  chartData,
  profitIndicator,
  customHelpTooltip,
  isLoading = true,
  isMoneyChart = false,
}: {
  label: string;
  title: string;
  chartData?: ChartData[];
  profitIndicator?: ReactNode;
  customHelpTooltip?: ReactNode;
  isMoneyChart?: boolean;
  isLoading: boolean;
}) {
  if (isMoneyChart) {
    label = parseMoneyValue(label) ?? '';
  }

  return (
    <Box className={styles.container}>
      {isLoading ? (
        <Box className="flex justify-center items-center h-[164px]">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className={styles.title}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Typography component="h3" className="text-gray-400">
                  {title}
                </Typography>
                {customHelpTooltip}
              </div>
              {profitIndicator}
            </div>
            <Typography className={styles.typographyTotal}>
              {label || 0}
            </Typography>
          </Box>
          <ResponsiveContainer>
            <CustomAreaChart
              isMoneyChart={isMoneyChart}
              data={chartData}
              title={title.split(' ')[1].replace(/[()]/g, '')}
            />
          </ResponsiveContainer>
        </>
      )}
    </Box>
  );
}
