type RangeTypes =
  | 'TODAY'
  | 'LAST_7_DAYS'
  | 'LAST_15_DAYS'
  | 'LAST_30_DAYS'
  | 'CURRENT_MONTH'
  | 'LAST_90_DAYS';

enum Ranges {
  'TODAY' = 0,
  'LAST_7_DAYS' = 7,
  'LAST_15_DAYS' = 15,
  'LAST_30_DAYS' = 30,
  'CURRENT_MONTH' = new Date().getDate() - 1,
  'LAST_90_DAYS' = 90,
}

const getLastDayOfLastMonth = (): number => {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  // Dia 0 no Date constructor o faz retornar o último dia do mês anterior
  const date = new Date(currentYear, currentMonth, 0);
  date.setHours(23, 59, 59);
  return date.valueOf();
};

export function parsePeriodRange(periodRange: RangeTypes) {
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - Ranges[periodRange]);

  // PreviousEnd é o mesmo que o Start menos 1 segundo para que
  // não haja interseção nos valores dos dois grupos
  const previousPeriodEndDate = new Date(startDate);
  previousPeriodEndDate.setSeconds(previousPeriodEndDate.getSeconds() - 1);
  const previousPeriodStartDate = new Date(previousPeriodEndDate);
  previousPeriodStartDate.setDate(
    previousPeriodStartDate.getDate() - Ranges[periodRange],
  );

  if (periodRange === 'TODAY') {
    startDate.setHours(0, 0, 0);
    previousPeriodStartDate.setDate(previousPeriodStartDate.getDate() - 1);
    previousPeriodStartDate.setHours(0, 0, 0);
    previousPeriodEndDate.setDate(previousPeriodEndDate.getDate() - 1);
    previousPeriodEndDate.setHours(23, 59, 59);
  }

  if (periodRange === 'CURRENT_MONTH') {
    startDate.setHours(0, 0, 0);
    previousPeriodStartDate.setMonth(startDate.getMonth() - 1);
    previousPeriodStartDate.setDate(1);
    previousPeriodStartDate.setHours(0, 0, 0);
    previousPeriodEndDate.setTime(getLastDayOfLastMonth());
  }

  return {
    startDate,
    endDate,
    previousPeriodStartDate,
    previousPeriodEndDate,
  };
}
