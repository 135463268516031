import HomeIcon from '@mui/icons-material/Home';
import { IconButton } from '@mui/material';
import VibBreadcrumbs, {
  BreadcrumbItem,
} from '@vibTheme/components/VibBreadcrumbs';
import React, { useState, useEffect, useRef, memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { parsePeriodRange } from '@utils/parsePeriodRange';
import { DashboardCard } from '@vibTheme/components/VibCharts';
import RefreshIcon from '@mui/icons-material/Refresh';
import { InputSelect, InputProduct } from '@vibTheme/components/_base/Input';
import { Typography } from '@vibTheme/components/_base/Typography';
import { getClientTz } from '@utils/getClientTz';
import { DashboardFilters } from '@interfaces/dashboard';
import { VibModal } from '@vibTheme/components/VibModal';
import { CustomPeriodRange } from '@components/Dashboard/CustomPeriodRange';

export default function Dashboard() {
  const { handleSubmit, control, watch } = useForm();
  const [dashboardFilters, setDashboardFilters] = useState<DashboardFilters>({
    botConfigIds: [],
    clientTz: getClientTz(),
    ...parsePeriodRange('LAST_30_DAYS'),
  });
  const formRef = useRef<HTMLFormElement>(null);
  let onChangeCallBackTimeout: NodeJS.Timeout;

  useEffect(() => {
    formRef.current?.requestSubmit();
  }, []);

  const formFieldsOnChangeCallback = () => {
    clearTimeout(onChangeCallBackTimeout);
    onChangeCallBackTimeout = setTimeout(() => {
      formRef.current?.requestSubmit();
    }, 500);
  };

  function onSubmit(data: any) {
    if (data.periodRange === 'CUSTOM') return;

    const datesFilter = parsePeriodRange(data.periodRange);
    const filters = { ...data, ...datesFilter, clientTz: getClientTz() };

    setDashboardFilters(filters);
  }

  // Custom period range
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const periodRange = watch('periodRange');
  useEffect(() => {
    periodRange === 'CUSTOM' && setIsDialogOpen(true);
  }, [periodRange]);

  const periodRangeOptions = useMemo(
    () => [
      { value: 'TODAY', label: 'Hoje' },
      { value: 'LAST_7_DAYS', label: 'Ultimos 7 dias' },
      { value: 'LAST_15_DAYS', label: 'Ultimos 15 dias' },
      { value: 'LAST_30_DAYS', label: 'Ultimos 30 dias' },
      { value: 'CURRENT_MONTH', label: 'Mês atual' },
      { value: 'LAST_90_DAYS', label: 'Ultimos 90 dias' },
      {
        value: 'CUSTOM',
        label: 'Personalizado',
        onClick: () => setIsDialogOpen(true),
      },
    ],
    [],
  );

  return (
    <>
      <section>
        <VibBreadcrumbs items={breadcrumbs} />
        <div className="mb-main sm:flex sm:gap-main sm:items-center md:justify-between">
          <Typography component="h1" className="mb-main sm:mb-0">
            Dashboard
          </Typography>

          <form
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
            className="grid gap-main grow lg:grow-[0.3] sm:grid-cols-[1fr_1fr_50px] max-w-[600px]"
          >
            <InputSelect
              name="periodRange"
              control={control}
              label="Período"
              options={periodRangeOptions}
              defaultValue={periodRangeOptions[3].value}
              onChangeCallback={formFieldsOnChangeCallback}
              className="w-full"
            />
            <InputProduct
              name="botConfigIds"
              control={control}
              label="Produto"
              onChangeCallback={formFieldsOnChangeCallback}
              className="w-full"
              selectedLocalStorageId="dashboard"
              userRolesFilter={['OWNER', 'COPRODUCER', 'AFFILIATED']}
            />
            <IconButton
              onClick={formFieldsOnChangeCallback}
              aria-label="refresh"
              color="secondary"
              size="medium"
              className="hidden sm:block"
            >
              <RefreshIcon />
            </IconButton>
          </form>
        </div>

        <MemoizedDashboardCard dashBoardFilters={dashboardFilters} />
      </section>

      <VibModal
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        title="Período personalizado"
      >
        <CustomPeriodRange
          setDashboardFilters={setDashboardFilters}
          setIsOpen={setIsDialogOpen}
        />
      </VibModal>
    </>
  );
}

const MemoizedDashboardCard = memo(
  DashboardCard,
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
);

const breadcrumbs: BreadcrumbItem[] = [
  {
    label: 'Dashboard',
    icon: <HomeIcon />,
  },
];
